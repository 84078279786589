import React from 'react';

export default function PrivacyPolicy() {
  return (
    <section class="wrapper container container--med block--content content-text">
      <div id="privacypolicy" data-gtm-vis-recent-on-screen-7808304_20="5806" data-gtm-vis-first-on-screen-7808304_20="5806" data-gtm-vis-total-visible-time-7808304_20="1000" data-gtm-vis-has-fired-7808304_20="1">
        <p><a name="terms"></a></p>
        <h2>Privacy Policy</h2>
        <h4><b>(updated as of August 15, 2019)</b></h4>
        <p><span>This Privacy Policy explains how information about you is collected, used, shared, stored and secured by Imgstocks, Inc. and our subsidiaries (collectively referred to as “Imgstocks,” “we,” “our” and “us”) when you use our websites, products and/or services (collectively, the “Services”) or when you otherwise interact with us.&nbsp; This Privacy Policy also explains your choices regarding your information. </span></p>
        <h3></h3>
        <h3>OVERVIEW</h3>
        <p><span>Imgstocks is committed to protecting your privacy and complying with all applicable laws in collecting and processing personal information.</span></p>
        <p>This Privacy Policy provides information regarding the following:</p>
        <ul>
          <li><span>What information we collect</span></li>
          <li><span>How we use your information</span></li>
          <li><span>How we use cookies</span></li>
          <li><span>How we share your information</span></li>
          <li><span>How we store and secure your information</span></li>
          <li><span>How you can opt out from receiving communications, or change or request deletion of your information</span></li>
          <li><span>Payment processing</span></li>
          <li><span>Other important privacy information</span></li>
        </ul>
      </div>
      <p><span>Our Services may contain links to other websites operated by third parties.&nbsp; Information collected by such third parties is governed by their privacy policies which we encourage you to review to learn about the privacy practices of those third parties.</span></p>
      <h3>WHAT INFORMATION WE COLLECT</h3>
      <p><span>We collect information about you when you provide it to us, when you use the Services, and/or when other sources provide it to us, as described in more detail below.</span></p>
      <ol>
        <li><b>Information You Provide to Us</b><span>. We collect information about you when you input it into the Services (such as completing a web form or using our chat function) or otherwise provide it directly to us. For example, we collect information when you submit a request for a demonstration or customer support or otherwise communicate with us, or when we establish an account for you. The types of information we may collect include your contact information or your billing contact information (such as your name, physical addresses or geographic location, email addresses and phone numbers), your employer or other company affiliation, your business title, and any other information you choose to provide. If we establish an account for you for use of paid and/or secured access portions of the Services, such as an account established in connection with a license to use the Imgstocks aiWARE™ Platform (the “Platform”), we also collect your username and password for such account.&nbsp;</span></li>
        <li><b>Information We Collect Automatically When You Use the Services</b><span><span><span>. We collect information about you when you use our Services, including browsing our websites and taking certain actions within the Services.&nbsp; For example, we collect and log information about the features you use, your access times, pages viewed, browser type, IP address, Internet service provider and/or mobile carrier, URLs of referring/exit pages, and information about the computer, phone, tablet or other devices you use to access the Services, including hardware model, operating system and version, and unique device identifiers.</span></span></span>&nbsp;
<p>In connection with your use of the Platform and related Services, we log information related to your upload, processing, access and playback of media and the associated results generated by the Platform, frequently used search terms, creation of Collections and Watchlists, and other actions taken.We collect information when you use the Services using various technologies, such as cookies, as described under the heading “<b>How We Use Cookies</b>” below.</p></li>
        <li><b>Information We Collect From Other Sources</b><span>.&nbsp; If you use the Services on behalf of a company or other organization (such as your employer), we may collect information about you from such company or organization in order to establish an account for you and/or provide support or other Services to you.&nbsp; We may obtain information about you from third-party partners that market and promote our Services, generate leads for us, resell our Services, or provide other services to us. We may also obtain information about you from other sources, including information from publicly and commercially available sources and other third parties, as permitted by law.&nbsp; The types of information we may collect from any of these other sources generally include your contact information (including your name, physical addresses or geographic location, email addresses and phone numbers), your employer or other company affiliation and your business title.</span></li>
      </ol>
      <h3>HOW WE USE YOUR INFORMATION</h3>
      <p><span>We use information about you to provide the Services to you, to communicate with you in connection with your use of the Services and/or requests for information, to market and promote the Services, and to maintain and operate the Services, as follows:</span></p>
      <ol>
        <li><b>To Provide Services to You</b><i><span>.</span></i><span> We use your information to provide Services to you, including to authenticate you when you log in to the Platform (or other Services with secured access), to configure features of the Services for you, and to operate the Services.</span></li>
        <li><b>To Communicate with You.</b><span> We use your information to communicate with you, including in providing support through our telephone, email and chat support channels, and in responding to your requests for information, questions and comments related to Imgstocks’s business, products and/or services.&nbsp; We may also send you technical notices, updates, service notifications, security alerts, support, billing and administrative messages in connection with your use of the Services.&nbsp;&nbsp;</span></li>
        <li><b>To Inform You of Offerings.</b><span> We use your information to communicate with you about products, services, offers, promotions, rewards, and events offered by Imgstocks and others, and provide news and information that we think will be of interest to you.&nbsp; You may opt out from receiving these types of communications at any time, as discussed under the heading “</span><b>Opt Out From Receiving Communications</b><span>” below.</span></li>
        <li><b>To Maintain, Monitor and Secure the Services.</b><span> We may use certain information to monitor and analyze trends, usage and activities in connection with our Services; to monitor, detect, investigate and/or prevent suspicious, fraudulent, unauthorized or illegal activity; and to secure the Services and communicate with you regarding any security incidents.</span></li>
      </ol>
      <h3>HOW WE USE COOKIES</h3>
      <p><span>We use cookies and similar technologies such tracking pixels and web beacons (collectively, “cookies”) to collect information about your use of our Services, your browsing activities and preferences, and to improve and customize your user experience and interactions with us.&nbsp; A cookie is a small text file that is stored on your browser or the hard drive of your computer. We may use the following cookies:</span></p>
      <ul>
        <li><b>Necessary cookies.</b><span> These are cookies that are required for the operation of certain Services, including, for example, cookies that enable you to log in to secure areas of the Services.</span></li>
        <li><b>Analytical cookies. </b><span>These cookies collect information about how you use the Services. They allow us to recognize and count the number of visitors and to see how visitors move around within the Services. These cookies are sometimes placed by third-party providers of web traffic analytics services, such as Google Analytics.&nbsp;&nbsp;</span></li>
        <li><b>Functionality cookies. </b><span>These cookies remember choices you make and are used to recognize you when you log in to the Services. This enables us to personalize the Services for you, greet you by name and remember your preferences (for example, your choice of language or region).</span></li>
        <li><b></b><b>Targeting or Advertising cookies.&nbsp; </b><span>These cookies collect information about your browsing activities in order to understand your interests for marketing purposes. We or third parties may place or recognize these cookies on your browser when you visit certain websites for the purpose of serving you with advertisements regarding our products and services that may be of interest to you. These cookies may also be used for frequency capping purposes (e.g., to ensure we do not display the same advertisement to you repeatedly) and to help us regulate the advertisements you receive and measure their effectiveness.&nbsp; We also use tracking technologies in our advertisements, email newsletters and/or other communications to collect information such as whether you clicked on an advertisement, opened our communication or clicked on any of the content contained therein. </span></li>
      </ul>
      <p><span>You can withdraw or modify your consent to accept cookies by changing the settings on your browser. However, if you choose to disable this function, your experience using the Services may be diminished and some features may not work as intended.</span></p>
      <h3>HOW WE SHARE YOUR INFORMATION</h3>
      <p><span>We may share information about you as discussed below.</span></p>
      <ol>
        <li><b>With Other Users.</b><span>&nbsp; In connection with your use of certain features of the Services, such as sharing Collections, media or processing results generated by or through the Platform with others, we may share information about you (such as your name and email address) with such other persons.</span></li>
        <li><b>With Service Providers. </b><span>&nbsp;We work with third parties that provide to us engine or application development and/or processing services; information systems hosting, infrastructure, maintenance, backup, storage, security, support and other information technology-related services; and consulting and other technical and/or business services, which may require access to information about you to perform such services on our behalf.&nbsp; We enter into agreements with our third-party service providers that require protection of your information consistent with this Privacy Policy.</span></li>
        <li><b>With Partners</b><span>. We work with third-party partners that market, resell and provide support related to our Services.&nbsp; We may share your information with such partners in connection with such activities. We enter into agreements with our partners that require protection of your information consistent with this Privacy Policy.</span></li>
        <li><b>With Your Consent</b><span>.&nbsp; We share information about you with third parties when you give us consent to do so – for example, if you have agreed to be a reference for Imgstocks or have provided a testimonial regarding our Services.</span></li>
        <li><b>For Legal and Compliance Purposes</b><span>.&nbsp; We may share information about you with a third party if we believe that sharing such information is reasonably necessary to (a) comply with any applicable law, regulation, legal process or governmental request, including to meet national security requirements, (b) enforce our agreements, policies and terms of service, (c) protect the security or integrity of our products and services, and (d) protect Imgstocks, our customers, employees or other persons from harm or illegal activities.&nbsp; We may also share your information in connection with, or during negotiations of, any merger, sale of all or a portion of our business to another company, subject to confidentiality obligations.</span></li>
      </ol>
      <h3>HOW WE STORE AND SECURE YOUR INFORMATION</h3>
      <p><span>Imgstocks has implemented and maintains technical and organizational security measures and other safeguards reasonably necessary to ensure the security, confidentiality and integrity of your information, including measures to protect against unauthorized access to, destruction, modification, disclosure or use of your information, as appropriate based on the nature of the information. We permit persons working for us to access and use your information only to serve our legitimate business needs, in accordance with this Privacy Policy.</span></p>
      <p><span>Imgstocks is based in the United States, and we generally store your information on servers located in the United States.&nbsp; We also conduct business in other countries, including the United Kingdom, directly or through one of our subsidiaries or partners, and may transfer your information to and/or store it in such other locations as reasonably necessary to provide the Services or for other legitimate business needs, in accordance with this Privacy Policy.</span></p>
      <p><span>While we follow generally accepted standards to protect your information, no method of storage or transmission is 100% secure. You are solely responsible for protecting your password, limiting access to your devices and signing out of websites after your sessions.</span></p>
      <h3><b>HOW YOU CAN OPT OUT FROM RECEIVING COMMUNICATIONS,&nbsp; CHANGE OR REQUEST DELETION OF YOUR INFORMATION</b></h3>
      <p><span>You may choose the types of communications you wish to receive from us, change your information or request that we delete certain information at any time, as follows:</span></p>
      <ol>
        <li><b>Opt Out From Receiving Communications</b><span>.&nbsp; If you do not wish to receive communications from Imgstocks that do not relate directly to your use of the Services (such as newsletters, product updates, event notifications or other marketing communications), you may opt out from receiving such communications at any time.&nbsp; We provide a link in all of our electronic marketing communications to allow you to easily “unsubscribe” to these communications. You may also notify us that you do not wish to receive further communications by contacting us at privacy@Imgstocks.com. Please note that, in connection with your use of certain Services, including the Platform, we will still need to send you certain notifications that relate directly to your use of the Services, such as technical notices, updates, service notifications, security alerts, billing and administrative messages, and will need to communicate with you to provide you with support.&nbsp;</span></li>
        <li><b>Change Your Information</b><span>.&nbsp; You may change or update your information with us at any time.&nbsp; To change or update your information, please contact us at privacy@Imgstocks.com, or contact your primary Imgstocks support representative.&nbsp; However, if your information has been provided to us by your employer or other company for which we have provisioned your account, updates to certain information on your account must be requested by such company.</span></li>
        <li><b>Delete Your Information.</b><span><span>&nbsp; You may request that we delete your information at any time, subject to certain exceptions as discussed below. You may request to have your information deleted by contacting us at privacy@Imgstocks.com. We will comply with your request within a reasonable period of time; however, in certain circumstances, we may not be able or required to delete all of your information.&nbsp; For example, if we have provisioned a user account for you and you intend to continue to use portions of the Services requiring access through such account, we must retain certain information in order to maintain your account and your access to the Services. We may also retain certain information if required in connection with our performance of a contract (such as a license agreement between us and your employer), if required by law or relevant to a legal proceeding, or for other compliance or auditing purposes, as permitted by law.</span></span>&nbsp;
      <p>Please note that we may obtain information about you in the future, as discussed under the heading “Information We Collect From Other Sources,” and we may not be able to recognize it as your information due to complying with a previous deletion request from you.&nbsp; In this case, you will need to submit a new request if you wish to have your re-added information deleted.</p></li>
      </ol>
      <h3 class="m_-2472734623351481106gmail-Legal2L1">PAYMENT PROCESSING</h3>
      <p><span>We utilize third-party service providers that act as resellers of, and/or payment processors for the fees payable for, licenses to certain of our Services and/or content that may be purchased through our websites (each, a “Payment Processor”). In order to purchase such Services through our website, you will be required to provide credit/debit card and/or financial institution account information directly to the Payment Processor.&nbsp; Such information is not collected or stored by us, and the Payment Processor’s privacy policy will apply to all information that you provide to the Payment Processor through the purchase and payment process or otherwise. We will not be responsible for any act or omission of any Payment Processor, including any failure of the Payment Processor to comply with its privacy policy or any other terms and conditions governing its resale of licenses or payment processing.</span></p>
      <h3>OTHER IMPORTANT PRIVACY INFORMATION</h3>
      <h4></h4>
      <h4>Other Rights of EEA Residents</h4>
      <p><span>If you are a resident of the EEA, you have certain other rights regarding our processing of your personal data (in addition to those discussed elsewhere in this Privacy Policy), in accordance with European data protection laws, and subject to certain restrictions and exemptions under such laws.&nbsp; These rights include, among others: (1) the right to be informed of the specifics regarding your personal data, including what data is being processed, the purposes for such processing, the third parties with which we share your data, the sources from which we obtain your data, and the period of time for which your personal data will be stored; (2) the right to receive a copy of your information that we process; and (3) the right to ask that we provide your data in an easily readable format to another company.&nbsp; EEA residents may contact us at privacy@Imgstocks.com to request such information or data.</span></p>
      <h3>Imgstocks Acting as a Data Processor</h3>
      <p><span>In connection with the use of certain Services by our customers or other parties, such as under license agreements pertaining to use of our Platform and related Services, our customers or other parties and their designated end users may upload, process, store and/or share data through the Services (or may provide data to us through other means for processing), the contents of which are generally not known to us but may contain personal data.&nbsp; In these circumstances, we are acting as a data processor. We process such data in accordance with the terms and conditions of our written agreements with, and any related instructions received from, such customers and other parties.</span></p>
      <h3>Use by Children</h3>
      <p><span>Our Services are not intended to be used by individuals under the age of 16, and we do not knowingly collect personal information from children under 16. If we become aware that a child under 16 has provided us with personal information, we will take steps to delete such information. If you become aware that a child has provided us with personal information, please contact us at <a href="mailto:hondasuytu.ht@gmail.com">hondasuytu.ht@gmail.com</a>.</span></p>
      <h3><b>Changes to our Privacy Policy</b></h3>
      <p><span>We may change this Privacy Policy from time to time. We will post any Privacy Policy changes on this page, with a revised date at the top of this page. If the changes are significant, we will provide a more prominent notice by adding a notice on the homepages of our websites or login screens for the Services, or by sending you an email notification. We encourage you to review the Privacy Policy whenever you access the Services to stay informed about our data privacy practices and your choices regarding your information.</span></p>
      <p><span>If you disagree with this Privacy Policy and/or any changes hereto, you will need to stop using the Services.</span></p>
      <h3>CONTACT</h3>
      <p><span>If you have any questions regarding this Privacy Policy, please email us at&nbsp;<a href="mailto:hondasuytu.ht@gmail.com">hondasuytu.ht@gmail.com</a>&nbsp;or write to:</span></p>
    </section>
  )
}