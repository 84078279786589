import React from 'react';

export default function ToS() {
  return (
    <section class="wrapper container container--med block--content content-text">
      <p><strong>Terms of Service</strong></p>
      <p><span >Last Updated: February 17, 2018</span></p>
      <h4><span >1. INTRODUCTION</span></h4>
      <p><span >These Terms of Service (</span><b>“Terms”</b><span >) govern Your access and use of the imstocks.com website (the “Site”) and any software, programs, materials, and services made available through this Site (collectively, the </span><b>“Services”</b><span >). When You access and make use of the Site and the Services, in their present form or as they may be modified from time to time, You are agreeing to these Terms, which create a legally binding agreement between You and imstocks, Inc. (</span><b>“Imstocks”</b><span > or the </span><b>“Company”</b><span >)</span></p>
      <p><span >As used in these Terms, the terms “You” and “Your” will collectively mean You individually, as well as any entity, such as Your employer, authorizing You to access and use this Site and the Services.</span></p>
      <p><span >Unless otherwise stated, these Terms shall apply to all Services provided by imstocks through this Site. Additional agreements, terms and/or conditions may also be applicable to certain Services, as set forth or referenced in these Terms and/or on the Site, or as otherwise communicated to You by imstocks from time to time. You will be notified of any additional terms that apply to any Services and that are not otherwise referenced in these Terms or on the Site prior to Your election to make use of such Services.</span></p>
      <p><span >We suggest that You become familiar with the terms, conditions and restrictions of these Terms, and our Privacy Policy, which is incorporated herein by reference. We also suggest that You periodically review these Terms and the Privacy Policy, as they are subject to revision from time to time, at Imstocks’s sole discretion. The date shown under the title of this page identifies the last date that these Terms were updated. You understand and agree that by using the Services after updated Terms have been posted, You are agreeing to these Terms as amended.</span></p>
      <p><span >If You do not agree to any provision of these Terms as currently posted, or as may be amended from time to time by imstocks in its sole discretion, then You agree to immediately notify imstocks, and cease accessing this Site and using the Services.</span></p>
      <h4><span >2. INTENDED USER; ACCOUNT REGISTRATION AND SECURITY</span></h4>
      <p><b>Intended User; Personal Use.</b><span > The Site and the Services are intended for use by people who are 18 years of age or older. Persons under the age of 18 are prohibited from creating an account with imstocks. By using this Site and the Services, You affirm that You are at least 18 years of age. The Site is for your personal use. As a condition to your continued use of the Site, you warrant to imstocks that you will not use the Site for any purpose that is unlawful or prohibited by these Terms.</span></p>
      <p><b>Account Registration.</b><span > Account registration may be required to use certain Services available through this Site. You represent and warrant that You will provide accurate, current and complete information (including information about You and any other authorized users) in any registration or other account-related forms on the Site (the “Registration Information”), and in the event of any changes, You will promptly update Your Registration Information to keep it accurate, current and complete. In addition, You affirm that you have not been previously suspended or removed from the Services and do not have more than one imstocks account. imstocks reserves the right to refuse registration, or refuse or limit access to the Services to any person or entity, in our sole discretion. We may, without notice and in our sole discretion, terminate Your right to use the Services, or any portion of thereof, and block or prevent Your future access to and use of the Services, or any portion thereof.</span></p>
      <p><b>Account Security.</b><span > The confidentiality of Your username and password for Your account with imstocks is Your responsibility and You are solely responsible to imstocks for activities in Your account. You agree that You will not share Your log-in credentials (including Your password) with others, or do anything that might jeopardize the security of Your account. You also agree to notify imstocks immediately of any unauthorized or improper use of Your password and/or Your account.</span></p>
      <p><b>Personally Identifiable Information.</b><span > All personally identifiable information that You provide with Your Registration Information shall remain confidential and shall not be disclosed by imstocks for any purpose, other than pursuant to a court order issued by a court having the proper jurisdiction to compel any such disclosure.</span></p>
      <p><b>Electronic Communication.</b><span > When You register and use the Services, or send e-mails to us, You are communicating with us electronically and You consent to receive communications from us electronically. We will communicate with You by e-mail or by posting notices on this Site or through the Services. You agree that all agreements, notices, disclosures and other communications that we provide to You electronically satisfy any legal requirement that such communications be in writing.</span></p>
      <h4><span >3. LICENSE TO ACCESS AND USE THE Imstocks PLATFORM</span></h4>
      <p><span >The imstocks Platform and related Services accessible through this Site may only be accessed and used by You pursuant to a license granted to You by imstocks under a separate license agreement (which may be a written license agreement, order form, subscription form or other document generated or accepted by imstocks in writing, which evidences a license from imstocks), which shall be governed by the imstocks Master License Terms and Conditions at </span><a href="/terms-and-conditions"><span >www.Imstocks.com/terms-and-conditions</span></a><span > (the “Platform License Agreement”), which are incorporated herein by reference. You are strictly prohibited from accessing and using the imstocks Platform and related Services without a Platform License Agreement. You agree that You will not defeat, circumvent or modify any authentication technology or other security measures, controls, limitations, or content or functionality filters contained in or associated with this Site, the Platform or the Services, or otherwise attempt to access any aspect of the imstocks Platform or the Services that You have not been granted authorization to access under a Platform License Agreement.</span></p>
      <h4><span >4. COMMENTS AND OTHER CONTENT</span></h4>
      <p><span >Certain portions of the Site and the Services may allow You to post reviews, comments and other communications (collectively, </span><b>“Comments”</b><span >) relating to materials and content published, uploaded or otherwise made available through the Site or the Services. So long as any such Comments are not illegal, objectionable, defamatory, obscene, invasive of privacy, infringing on intellectual property rights, or otherwise injurious to others, You may post Comments. You may not use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of any Comments. imstocks reserves the right (but not the obligation) to remove Comments in its sole discretion.</span></p>
      <p><span >You grant us, our agents, licensees, and assigns an irrevocable, perpetual, non-exclusive right and permission to reproduce, encode, store, copy, transmit, publish, post, broadcast, display, publicly perform, adapt, modify, create derivative works of, exhibit, and otherwise use your Comments. Please note that this license continues even if You stop using this Site or the Services. You agree to indemnify, release, and hold us harmless from any all liability, claims, actions, loss, harm, damage, injury, cost or expense arising out of any Comments that You post.</span></p>
      <p><span >All rights, licenses and obligations of You and imstocks with respect to materials, data, audio files, video files and other content that are uploaded to, transmitted through or generated by the imstocks Platform shall be as set forth in the applicable Platform License Agreement.</span></p>
      <h4><span >5. LINKS</span></h4>
      <p><span >The Site and/or the Services may contain links to other web sites operated by third parties. The inclusion of any link to such sites does not imply endorsement by imstocks of the site, but is for Your reference and convenience only. imstocks has not reviewed all of the sites linked to the Site and is not responsible for the content or accuracy of any off-site pages or any other sites linked to the Site nor does imstocks recommend or endorse any information, products, services or procedures that may be mentioned on or represented on sites linked to the Site. Linking to any other off-site pages or other sites is at Your own risk and subject to the terms and conditions of use of any hyperlinked site.</span></p>
      <h4><span >6. TERM AND TERMINATION</span></h4>
      <p><b>Term. </b><span >The term of Your authorization to access and use the publicly available portions of the Site and the Services, which are free of charge, is for an indefinite period of time, but may be terminated by imstocks at any time by written notice to you without any penalty.</span></p>
      <p><b>Termination upon Notice.</b><span > imstocks may terminate Your rights to access and use the Site or the Services at any time, in its sole discretion, upon providing You with thirty (30) days advance notice. Any such notice shall be delivered to the email address that You provided with Your Registration Information.</span></p>
      <p><b>Termination upon Breach.</b><span > If You breach any provision of these Terms, including, but not limited to, providing false Registration Information, breach any provision of the license granted to You by imstocks by these Terms, engage in any unauthorized use of the Services, or engage in any prohibited act as identified in these Terms, imstocks may, in its sole discretion, terminate Your rights to access and use the Services immediately, without additional notice to You unless otherwise agreed to in writing between You and imstocks. Imstocks’s termination of Your rights to access and use the Services for any such breach is not an exclusive remedy and imstocks reserves all other rights at law and in equity it may have against You for any such breach.</span></p>
      <p><b>Term and Termination Rights Related to imstocks Platform.</b><span > If applicable, the term of Your license to assess and use the imstocks Platform, or any other Services requiring a separate license agreement, shall be as set forth in the Platform License Agreement or other applicable license agreement and subject to the termination provisions thereof.</span></p>
      <h4><span >7. PERMITTED USE OF THE SERVICES</span></h4>
      <p><span >The Services are the property of imstocks, Inc. and Your access to the Services is solely with our permission. Any unauthorized access or use will, among other things, be deemed a trespass, and we reserve the right to pursue our legal rights for any unauthorized access or use of the Services, including seeking civil remedies and equitable relief to the fullest extent possible, as well as referral of matters to appropriate law enforcement agencies, as we deem reasonably necessary.</span></p>
      <p><span >You expressly agree that You will only use the Services for lawful purposes.</span></p>
      <h4><span >8. DATA COLLECTION</span></h4>
      <p><span >You agree to allow imstocks to collect certain non-personal information about Your use of the Services, and about Your computer, browser, or other devices or programs You are using to access and use the Services (the </span><b>“Usage Data”</b><span >). You agree that imstocks may collect, analyze, and aggregate such Usage Data for its internal business purposes and share such Usage Data with Imstocks’s partners, affiliates and third-party service providers in connection with our provision of the Services.</span></p>
      <p><span >In addition to the foregoing, if You operate or otherwise manage a YouTube channel and wish to use the Services in connection with such YouTube channel, You will be required to be logged in to your YouTube account. If You use the YouTube data API to obtain applicable analytic information, when You first log into Your imstocks account, You will be prompted through the YouTube OAuth protocol to grant imstocks access to such analytic data. By granting such authorization, You agree that imstocks may access, integrate and use such YouTube analytic data generated by Your YouTube channel, in whole or in part, into the imstocks Platform on an aggregated basis.</span></p>
      <h4><span >9. Imstocks COPYRIGHTS, TRADEMARKS AND SERVICE MARKS</span></h4>
      <p><span >As between you and imstocks, imstocks retains full copyright ownership, rights and protection in all material contained on the Site (including but not limited to all software, HTML code and other code, or business methods). Except as otherwise expressly provided in these Terms and Conditions, you may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, rewrite, create derivative works from, transfer, or sell any material contained on the Site without the prior consent of imstocks.</span></p>
      <p><span >You may, however, view, download and print materials displayed on the Site for your own personal use, provided any printed versions include the copyright and other notices displayed with the materials on the Site.</span></p>
      <p><span >None of the material contained on the Site may be reverse-engineered, disassembled, decompiled, transcribed, stored in a retrieval system, translated into any language or computer language, retransmitted in any form or by any means (electronic, mechanical, photoreproduction, recordation or otherwise), resold or redistributed without the prior written consent of imstocks.</span></p>
      <p><span >All content of the Site is Copyright © 2014-2018 imstocks, Inc. (or its licensors). All rights reserved.</span></p>
      <p><span >The trademarks, logos, and service marks (collectively the </span><b>“Imstocks Trademarks”</b><span >) displayed on this Site and within the Services including, but not limited to: Imstocks®, aiWARE™, Conductor™, OS for AI™, are the registered and/or unregistered marks of imstocks, or are otherwise used in accordance with applicable law. Nothing contained within the Services should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any imstocks Trademarks displayed within the Services without Imstocks’s written permission. Your unauthorized use of the imstocks Trademarks displayed in the Services is strictly prohibited.</span></p>
      <h4><span >10. THIRD PARTY TRADEMARKS</span></h4>
      <p><span >The Site and Services may incorporate, access or utilize certain software, technology and/or content not written or developed by imstocks, but instead is provided by third parties under license or otherwise (</span><b>“Third Party Elements”</b><span >). You acknowledge that You shall comply with any terms and conditions associated with such Third Party Elements and that imstocks shall have no liability or responsibility for the operation or performance of any such Third Party Elements and imstocks shall not be liable for any damages, costs or expenses, direct or indirect, arising out of the performance or failure of any such Third Party Elements to perform as intended.</span></p>
      <p><span >imstocks may display trademarks on the Site and in the Services that are registered to, or are otherwise owned by third parties (</span><b>“Third Party Marks”</b><span >) and imstocks displays such Third Party Marks with the consent of their respective owners. All right, title and interest in and to Third Party Marks belong to their respective owners and neither imstocks nor You acquire any rights to such Third Party Marks.</span></p>
      <h4><span >11. YOUR TRADEMARKS AND SERVICE MARKS</span></h4>
      <p><span >As between You and imstocks, You or Your licensors own all right, title, and interest in and to Your trademarks, logos, and service marks (collectively, </span><b>“Your Trademarks”</b><span >). Except as expressly provided in these Terms or in the Privacy Policy, nothing contained on this Site or within the Services grants any license to imstocks, by direct grant, implication, estoppel or otherwise, any license or right to use any of Your Trademarks without Your permission.</span></p>
      <h4><span >12. FORWARD-LOOKING STATEMENTS</span></h4>
      <p><span >This Site and documents or other information linked to this Site, including without limitation press releases and webcasts, contain forward-looking statements that are based upon current expectations and that involve risks and uncertainties. Without limiting the generality of the foregoing, words such as “may,” “will,” “expect,” “believe,” “anticipate,” “intend,” “could,” “estimate” or “continue” or the negative or other variations thereof or comparable terminology are intended to identify forward-looking statements. In addition, any statements that refer to expectations, projections or other characterizations of future events or circumstances are forward-looking statements. The forward-looking statements are made pursuant to the Safe Harbor provisions of the Private Securities Litigation Reform Act of 1995. Actual results may differ materially from those stated in any forward-looking statements based on a number of factors and other risks more specifically identified in Imstocks’s most recent periodic reports and other Securities and Exchange Commission filings. Although imstocks believes that the assumptions underlying the forward-looking statements are reasonable, any of the assumptions could prove inaccurate and, therefore, there can be no assurance that the results contemplated in the forward-looking statements will be realized.</span></p>
      <h4><span >13. DISCLAIMERS, LIMITATION OF LIABILITY, RELEASES</span></h4>
      <p><span >YOU AGREE THAT USE OF THIS SITE AND THE SERVICES IS AT YOUR OWN RISK. YOU WILL BE RESPONSIBLE FOR PROTECTING THE CONFIDENTIALITY OF YOUR PASSWORD. NEITHER Imstocks NOR ANY OF ITS RESPECTIVE EMPLOYEES, SHAREHOLDERS, AGENTS, THIRD PARTY CONTENT PROVIDERS OR LICENSORS, REPRESENT OR WARRANT THAT YOUR USE OF THIS SITE OR THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THIS SITE, OR AS TO THE ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION, OR SERVICE PROVIDED THROUGH THIS SITE. THIS SITE IS PROVIDED ON AN “AS IS” BASIS WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OTHER THAN THOSE WARRANTIES WHICH ARE IMPLIED BY AND INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THESE TERMS. THE DISCLAIMERS CONTAINED IN THESE TERMS APPLY TO ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS, WHETHER FOR BREACH OF CONTRACT, TORTUOUS BEHAVIOR, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION.</span></p>
      <p><span >IN NO EVENT WILL Imstocks, OR ANY PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING OR MAINTAINING THIS SITE BE LIABLE FOR ANY DAMAGES, INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, ARISING OUT OF THE USE OF OR INABILITY TO USE THIS SITE.</span></p>
      <h4><span >14. INDEMNIFICATION</span></h4>
      <p><span >You agree that You will, at Your expense, indemnify, defend, settle, and hold imstocks, its directors, officers, shareholders, employees and agents harmless from and against all claims and expenses, including attorneys’ fees, arising out of Your use of the Services, including but not limited to any use of the Services that is not authorized by these Terms. In addition to the foregoing, in any such action, You will pay any judgment awarded against imstocks or any settlement agreed to by You, and any authorized expenses incurred by imstocks. imstocks reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by You, in which event You will assist and cooperate with imstocks in asserting any available defenses.</span></p>
      <h4><span >15. COPYRIGHT COMPLIANCE</span></h4>
      <p><span >To ensure compliance with the Digital Millennium Copyright Act (“DMCA”) (USC §§ 512 et. seq.), imstocks will take action on receipt of notice of alleged copyright infringement. If You are a copyright owner or representative of the owner and believe that a user has submitted or uploaded material that infringes upon Your United States copyrights, You may submit notification in accordance with the DMCA (USC 512(c)(3)) by providing imstocks with the following information in writing:</span></p>
      <ul>
        <li ><span >Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works are covered by a single notification, a representative list of such works;</span></li>
        <li ><span >Identification of the claimed infringing material and information reasonably sufficient to permit us to locate the material on this Site (such as the URL(s) of the claimed infringing material);</span></li>
        <li ><span >Information reasonably sufficient to permit us to contact You, such as an address, telephone number, and, if available, an Email address;</span></li>
        <li ><span >A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law; and</span></li>
        <li ><span >A statement by You, made under the penalty of perjury, that the above information in Your notification is accurate and that You are the owner of an exclusive right that is allegedly infringed or are authorized to act on the owner’s behalf; and Your physical or electronic signature.</span></li>
      </ul>
      <p><span >Please send all written correspondence of alleged infringements to:</span></p>
      <p><span >imstocks, Inc.</span></p>
      <p><span >575 Anton Boulevard</span></p>
      <p><span >Costa Mesa, California 92626</span></p>
      <p><span >Attention: Legal Department</span></p>
      <h4><span >16. LEGAL PROCEEDINGS</span></h4>
      <p><span >Any dispute relating to or arising from Your use of the Services or these Terms will be adjudicated by a court of competent jurisdiction sitting in the State of California, County of Orange and You expressly consent to the jurisdiction of the State and Federal courts sitting in Orange County, California for such purposes. In addition to any finding, relief granted or judgment awarded by such court, the prevailing party in any such action shall be awarded reasonable attorney’s fees and costs.</span></p>
      <h4><span >17. NOTICES</span></h4>
      <p><span >All notices regarding any matter pertaining to these Terms, or the policies referenced herein, including any notice of claim, summons or subpoena will be given by email, with a physical copy of such notice sent via United States Registered Mail (return receipt requested), or by a nationally recognized express courier, postage or air bill prepaid. Notices sent to imstocks, Inc., shall be sent to: 575 Anton Boulevard, Costa Mesa, CA 92626, Attention: Legal Department. Notices sent by imstocks shall be sent via email, with a physical copy sent (by United States Registered Mail or a nationally recognized courier) to the email and physical addresses You provided as part of Your Registration Information. Notice will be deemed effective twenty-four (24) hours after sending of an email, unless returned due to an invalid email address, in which case, notice shall be deemed effective upon receipt of physical copies, as confirmed by United States Postal Service, or courier, as applicable.</span></p>
      <h4><span >18. GENERAL</span></h4>
      <p><span >These Terms are to be construed in accordance with the laws of the State of California, without regard to its conflict of law provisions. These Terms, as updated from time to time, constitute the entire agreement between us with respect to Your use of this Site. Imstocks’s failure to enforce any breach of this Agreement by You does not constitute a waiver of Imstocks’s right to enforce any term of these Terms in the future for a similar breach.</span></p>
      <h4><span >19. ATTENTION</span></h4>
      <p><span >Section 1789.3 of the California Civil Code (the “Electronic Commerce Act of 1984”, California Civil Code, Section 1789.1, et seq.) requires us to inform You that You may contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Boulevard, Sacramento, California 95834 or by telephone at (916) 445-1254, or (800) 952-5210, in order to resolve any complaint regarding a service provided to You through this Site or to receive further information regarding the use of such services.</span></p>
    </section>
  )
}